@import url('https://fonts.googleapis.com/css2?family=Amarante&family=Poppins:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  body {
    overflow-x: hidden;
    color: rgba(255, 242, 209, 1);
    background-color: #16072b;
    font-family: Poppins;
  }

  .content-wrapper {
    min-height: calc(100vh - 235px);
    padding-bottom: 1rem;
    /* max-width: 2500px; */
    /* margin: 0 auto; */
  }
}

.background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

.bg-stars {
  background: url(assets/img/bg-stars.svg);
  background-repeat: repeat-y;
  background-size: 100vw;
  z-index: -200;
}

.background-cloud-left {
  background: url(assets/img/mission-card-bg.svg);
  z-index: -200;
  background-repeat: no-repeat;
}

.bg-hero-section {
  background: url(assets/img/hero-bg.svg);
  z-index: -200;
  background-repeat: no-repeat;
  background-position: bottom -56px right;
  background-size: 100%;
  padding-bottom: 50px;
}
.bg-footer {
  background: url(assets/img/bg-footer.svg);
  background-repeat: no-repeat;
  background-position: bottom -245px left;
  background-size: 100%;
}

.bg-position {
  background-position: top -110px right !important;
}

.bg-use-case {
  background: url(assets/img/use-case-bg.svg);
  z-index: -200;
  background-repeat: no-repeat;
  background-position-x: right;
}
.bg-body-cloud {
  background: url(assets/img/bg-body-cloud.svg);
  z-index: -200;
  background-repeat: no-repeat;
  background-position: top 200px right;
}

.bg-cloud-left {
  background: url(assets/img/cloud-left.svg);
  z-index: -200;
  background-repeat: no-repeat;
  background-position-x: left;
}

.bg-solution {
  background: url(assets/img/bg-solution.svg);
  z-index: -200;
  background-repeat: no-repeat;
  background-position: -70px -31px;
  padding-bottom: 140px;
  background-size: auto;
  padding-left: 40px;
  padding-top: 65px;
}

.bg-bottom {
  background: url(assets/img/bg-bottom.svg);
  z-index: -200;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.mission-section-box {
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.06), 0px 20.25px 34.875px rgba(0, 0, 0, 0.0405), 0px 8px 13px rgba(0, 0, 0, 0.03), 0px 1.75px 4.625px rgba(0, 0, 0, 0.0195);
  background: url(assets/img/mission-card-bg.svg), rgba(124, 78, 189, 0.2);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  border-radius: 16px;
  z-index: 1;
}

.glow {
  text-shadow: 0px -4px 12px rgba(255, 229, 171, 0.35);
}

@media only screen and (max-width: 600px) {
  .sticky-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background: #16002c;
    z-index: 1000;
  }

  .mission-section-box {
    box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.06), 0px 20.25px 34.875px rgba(0, 0, 0, 0.0405), 0px 8px 13px rgba(0, 0, 0, 0.03), 0px 1.75px 4.625px rgba(0, 0, 0, 0.0195);
    background: url(assets/img/mission.svg), linear-gradient(187.89deg, #6605BB 9.07%, #291645 85.41%);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    border-radius: 16px;
    z-index: 1;
    background-size: 100%;
  }

  .bg-hero-section {
    background-size: auto;
    background: url(assets/img/hero-bg-small.svg);
    padding-top: 0px;
    z-index: -200;
    background-repeat: no-repeat;
    padding-top: 60px;
    background-position: 0px 110%;
    background-size: 100%;
  }

  .bg-solution {
    background: url(assets/img/bg-solution-small.svg);
    z-index: -200;
    background-repeat: no-repeat;
    background-position: 0px 95%;
    background-size: 100%;
    padding-bottom: 48%;
    padding-left: 40px;
    padding-top: 65px;
    background-color: rgba(124, 78, 189, 0.2);
  }

  .bg-stars {
    display: none;
  }

  .bg-use-case {
    background: url(assets/img/use-case-bg-small.svg);
    z-index: -200;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position: 0px 40%;
    background-size: 100%;
  }

  .bg-cloud-left {
    background-size: 138%;
    background-position: 0px 60%;
  }

  .bg-footer {
    background: url(assets/img/bg-footer-small.svg);
    z-index: -200;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 100%;
  }
}

.gradient-border {
  border-left: 1px solid #F500FA;
  border-right: 1px solid #4100FA;
  background-image: linear-gradient(90deg, #F500FA, #4100FA), linear-gradient(90deg, #F500FA, #4100FA);
  background-size: 100% 1px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}

.faq-link {
  color: #7B61FF;
}

html {
  scroll-behavior: smooth;
}